  import React, { Component } from "react";

  import { Segment, Header,Form, Select, Table, Button, Icon, Menu, Input, Modal, Dropdown, Tab, Pagination } from 'semantic-ui-react'
  import "./FileImport.css";
  import { invokeApig, uploadS3Folder } from "../libs/awsLib";
  import { FilePond, registerPlugin } from 'react-filepond';
  import 'filepond/dist/filepond.min.css';
  import JSONEditor from "./JSONEditor";
  import { JSONDate, JSONDateTime, formatDateTime, convertLookupListtoObject, deepCopy } from "../libs/sharedFunctions";
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
  import LoadingPage from "./LoadingPage"
  import Cookies from 'universal-cookie';

  // Register the plugin
  registerPlugin(FilePondPluginFileValidateType);

  const PAGE_SIZE=5;
  const NO_PAGES=10;
  
  
  export default class FileImport extends Component {
    constructor(props) {
      super(props);
      this.updateStructuredData=this.updateStructuredData.bind(this);
      this.updateSaveButton=this.updateSaveButton.bind(this);
      this.mergeEntity=this.mergeEntity.bind(this);
      this.removeEntity=this.removeEntity.bind(this);
      // AWS.config.update({
      //   secretAccessKey: config.aws.secretAccessKey,
      //   accessKeyId: config.aws.accessKeyId,
      //   region: config.aws.region
      // });   
      
      // this.showFields={"customer": ["name", "subbuildingname", "buildingname", "buildingnumber", "dependentthoroughfare", "thoroughfare", "doubledependentlocality", "dependentlocality", "posttown", "county", "postcode", "type", "sic", "companynumber", "vatregistrationnumber", "charitynumber", "password", "businessstructure", "limitedcompanyname", "homebuildingname", "homebuildingnumber", "homethoroughfare", "homeposttown", "homepostcode", "soletradertitle", "soletraderfirstname", "soletraderlastname", "soletraderphone", "soletradermobile", "soletraderemail", "soletraderaddresshistory", "soletraderdateofbirth", "soletraderotherpartners"], "contract": ["termsandconditionsid", "commissionstructureid", "startdate", "durationmonths", "signeddate"], "account": ["name", "subbuildingname", "buildingname", "buildingnumber", "dependentthoroughfare", "thoroughfare", "doubledependentlocality", "dependentlocality", "posttown", "county", "postcode"], "contact": ["title", "firstname", "initials", "lastname", "position", "landline", "fax", "mobile", "email", "preferredcontactmethod"], "site": ["name", "subbuildingname", "buildingname", "buildingnumber", "dependentthoroughfare", "thoroughfare", "doubledependentlocality", "dependentlocality", "posttown", "county", "postcode", "siteusage", "manned24hours"], "product": ["utilityid", "vatcodeid", "qualifyingpercentage"], "elecmeterpoint": ["mpancore", "profileclassid", "linelossfactorclassid", "metertimeswitchcode", "estimatedannualconsumption", "availablesupplycapacity", "hhprofileclassid"], "gasmeterpoint": ["meterpointreference", "supplymeterpointaq"], "directdebit": ["accountname", "sortcode", "accountnumber"]}
      this.subObjects={}
      this.state = {
          // fileformat: {}, 
          fileformatname: null, 
          product: null, 
          importdays: 1, 
          isLoading: true, 
          imports: [], 
          fileimportstatus: {}, 
          fileimportrejectionreason: {}, 
          selectedrowkey: -1, 
          selectedrow: {'importstatus': null}, 
          editmode: false, 
          activeObjectIndex: 0, 
          dataChanged: false, 
          manualverification: true, 
          importPage: 1,
          showeSignModal: false,
          accountmanager: {firstname: null, lastname: null},
          esigndata: {firstname: null, lastname: null, jobtitle: null, phonenumber: null, email: null},
          esignlock: false,
          processRenewalLock: false,
          importFilter: 0,
          searchPage: 1
      };
      this.inputRefs={}
      

      
    }   
 
    componentDidMount()
    {
      this.loadCookies();
      this.loadlookups();
      this.refreshdata(this.state.importdays);
    }

    async componentWillReceiveProps(newProps)
    {
      //refresh if tpi updated
      //console.log(this.props.sourceid, newProps.sourceid)
      if (this.props.sourceid !== newProps.sourceid)
      {
        await this.loadCookies();
        this.refreshdata(this.state.importdays,-1, newProps.sourceid)
        this.setState({product: null, fileformatname: null});
      }
    }

   loadlookups = () => {
      invokeApig({path: "/lookups/fileimportstatus", method: "GET",  body: null}).then((result)=>{this.setState({fileimportstatus: convertLookupListtoObject(result.data)}
          )}).catch((err)=>{console.log(err)});      
      invokeApig({path: "/lookups/fileimportrejectionreason", method: "GET",  body: null}).then((result)=>{this.setState({fileimportrejectionreason: convertLookupListtoObject(result.data)}
          )}).catch((err)=>{console.log(err)});      

  }

    async refreshbutton(){
      await this.loadCookies();
      this.refreshdata(this.state.importdays);
    }

    refreshdata(importdays, selectrowkey=-1, sourceid=this.props.sourceid) 
    {
      
      // preserve selected row
      if (!this.state.showUploadModal){this.setState({isLoading: true})}
      const oldselectedrowkey=this.state.selectedrowkey;

      var fromdate=new Date()
      fromdate.setDate(fromdate.getDate()-importdays);
      invokeApig({path: '/api/fileimports/0', method: "GET",  queryParams: ['importdatetime>'+JSONDate(fromdate), 'sourceid='+sourceid]}).then((results)=>
      {
        // var selectedrowkey=-1;
        this.setState({isLoading: false})
        var selectedrow= {'importstatus': null};
        if (selectrowkey>-1)
          if (results.data.length>selectrowkey)
            {
              selectedrow=results.data[selectrowkey]
            }
        
        this.setState({imports: results.data.reverse(), isLoading: false, selectedrowkey: selectrowkey, selectedrow: selectedrow, importFilter: 0})
      })
    }

    loadCookies()
    {
      const cookies=new Cookies();
      const importDaysCookie=cookies.get('sgSign-importdays');
      var importDays=1;
      if (importDaysCookie) {
        importDays=Number(importDaysCookie);
      }
      this.setState({importdays: importDays});
    }
  
    getrowdata(fileimportid, i){
      invokeApig({path: '/api/fileimports/'+fileimportid, method: "GET"}).then((results)=>
      {
        this.setState({isLoading: false, selectedrowkey: i, selectedrow: results.data, esigndata: {firstname: null, lastname: null, jobtitle: null, phonenumber: null, email: null}, accountmanager: {firstname: null, lastname: null}})
        if (results.data.structureddata){
          if (results.data.structureddata.length>0){
            if ('accountmanager' in results.data.structureddata[0]){
              this.setState({accountmanager: results.data.structureddata[0].accountmanager})
            }
            if ('esigndata' in results.data.structureddata[0]){
              this.setState({esigndata: results.data.structureddata[0].esigndata})
            }
          }
        }
      })

    }
    
    updaterawdatavalue(rowindex, colindex, column, rawvalue)
    {
      const parsecolumn=column.format.split(':');
      const type=parsecolumn[0];
      var value=rawvalue;

      if (rawvalue)
      {
      if (type==='integer')
      {
        value=parseInt(rawvalue)
      }
      }
      var newData=Object.assign({}, this.state.selectedrow)
      newData.rawdata[rowindex][colindex]=value  
      this.setState({selectedrow: newData, dataChanged: true})
      // this.forceUpdate()
    }

    renderrawdatafield(column, rowindex, colindex, errors)
    {
      const parsecolumn=column.format.split(':');
      const type=parsecolumn[0];
      const key=(rowindex+1).toString() +'_'+column.column.toString();
      const inputRef=React.createRef();
      this.inputRefs[key]=inputRef;
      const colerror=this.columnerror(rowindex+1, colindex+1, errors);

      if (type==='text')
      {
        if ('transformation' in column)
        {
          const options=Object.keys(column.transformation).map(key=>{return {value: key, text: key, key: key}})
          return <Select error={colerror} disabled={!this.state.editmode} ref={inputRef} options={options} value={this.state.selectedrow.rawdata[rowindex][colindex]} onChange={(event, data)=>{this.updaterawdatavalue(rowindex, colindex, column, data.value)} }/>
        } 
        else
        {
          return <Input error={colerror} disabled={!this.state.editmode} ref={inputRef} type='text' maxLength={parsecolumn[1]} value={this.state.selectedrow.rawdata[rowindex][colindex]} onChange={(event, data)=>{this.updaterawdatavalue(rowindex, colindex, column, data.value)}}/>
        }
        
      }
      else if (type==='integer')
      {
        return <Input error={colerror} disabled={!this.state.editmode} ref={inputRef} type='number' maxLength={parsecolumn[1]} value={this.state.selectedrow.rawdata[rowindex][colindex]} onChange={(event, data)=>{this.updaterawdatavalue(rowindex, colindex, column, data.value)}}/>
      }
      else if (type==='date')
      {
        return <Input error={colerror} disabled={!this.state.editmode} ref={inputRef} type='date' value={this.state.selectedrow.rawdata[rowindex][colindex]} onChange={(event, data)=>{this.updaterawdatavalue(rowindex, colindex, column, data.value)}}/>
      }
      else if (type==='boolean')
      {
        return <Input error={colerror} disabled={!this.state.editmode} ref={inputRef} type='checkbox' checked={this.state.selectedrow.rawdata[rowindex][colindex]===true} onChange={(event, data)=>{console.log('data', data); this.updaterawdatavalue(rowindex, colindex, column, this.state.selectedrow.rawdata[rowindex][colindex] ? false : true)}}/>
      }


    }
    
    getcolumn(columnindex)
    {
      return this.props.fileformats[this.state.selectedrow.fileformat].columns.find(column=>{return column.column===columnindex})    
    }

    
    rejectImport(rejectionreasonid)
    {
      var fileimport={};
      Object.assign(fileimport, this.state.selectedrow)
      fileimport.importstatus=10;
      fileimport.rejectionreason=parseInt(rejectionreasonid);
      this.setState({selectedrow: fileimport})
      invokeApig({path: '/api/fileimports/'+this.state.selectedrow.id, method: 'PUT', body: fileimport}).then(result=>{console.log(result); this.setState({editmode: false, dataChanged: false}); this.refreshdata(this.state.importdays)}).catch(err=>{console.log(err)})
    }


    saveUpload()
    {
      var fileimport={};
      Object.assign(fileimport, this.state.selectedrow)
      fileimport.fileformat=fileimport.fileformatname;
      fileimport.rawdata=JSON.stringify(fileimport.rawdata);
      fileimport.structureddata=JSON.stringify(fileimport.structureddata);
      fileimport.validationfailures=JSON.stringify([]);
      fileimport.manualverification=this.state.manualverification;
      
      invokeApig({path: '/api/fileimports/'+this.state.selectedrow.id, method: 'PUT', body: fileimport}).then(result=>{console.log(result); this.setState({editmode: false, dataChanged: false}); this.refreshdata(this.state.importdays)}).catch(err=>{console.log(err)})
    }

    manualUpload()
    {
      var parameters=this.props.parameters;
      this.props.parameterfields.forEach(parameterField=>{parameters[parameterField]=this.props.tpi.products[this.props.manualuploadproduct][parameterField]});

      const data={
        importdatetime: JSONDateTime(new Date()),
        importstatus: 4, 
        fileformat: this.props.manualuploadformat, 
        sourcetype: this.props.sourcetype, 
        sourceid: this.props.sourceid, 
        userid: this.props.userid, 
        structureddata: '[]', 
        validationfailures: '[]', 
        warnings:[], 
        parameters: JSON.stringify(parameters), 
        manualverification: true, 
        productdefinition: this.props.manualuploadproduct
      }
      invokeApig({path: '/api/fileimports', method: 'POST', body: data}).then(result=>{console.log(result); this.refreshdata(this.state.importdays);}).catch(err=>{console.log(err)})

    }

    updateSaveButton()
    {
      this.setState({dataChanged: true})
    }

    updateStructuredData(i, data)
    {
      var newData=Object.assign({}, this.state.selectedrow);
      newData.structureddata[i]=Object.assign({}, data);
      this.setState({selectedrow: newData, dataChanged: true})
    }

    removeEntity(id)
    {

      var newData=Object.assign({}, this.state.selectedrow);
      newData.structureddata[id].visible=false
      this.setState({selectedrow: newData, dataChanged: true, activeObjectIndex: newData.structureddata.length>0 ? 0 : null})
    }
    
    
    getObjectByKey(object, key)
    {
      console.log(object, key)
    return key.split('.').reduce((subobject, subkey)=>
        {
          // console.log(subkey, JSON.stringify(subobject))
            if (subkey===this.props.toplevelentity)
            return subobject
          else 
          {
            const parseobjectkey=subkey.split(':');
            return subobject[parseobjectkey[0]][parseInt(parseobjectkey[1])]
          }
        }, object)
        // return subobject;
    }

    addTopLevelEntitiy()
    {
      var newData=deepCopy(this.state.selectedrow);
      var newObject=deepCopy(this.props.manualfunctions[''][0].parameters[0]);
      newData.structureddata.push(newObject);
      this.setState({selectedrow: newData, dataChanged: true, activeObjectIndex: newData.structureddata.length>-1})
    }

    mergeEntity(sourceid, sourcekey, targetid)
    {
      console.log(sourceid, sourcekey, targetid)
      var newData=Object.assign({}, this.state.selectedrow.structureddata);
      console.log(JSON.stringify(newData))
      const sourceObject=newData[sourceid]
      const targetObject=newData[targetid]
      console.log(JSON.stringify(sourceObject))
      console.log(sourcekey)
      const subObject=this.getObjectByKey(sourceObject, sourcekey);
      const parentKey=sourcekey.split('.').slice(0,-1).join('.')
      const targetParent=this.getObjectByKey(targetObject, parentKey);
      
      const targetKey= sourcekey.split('.').slice(-1)[0]
      const targetArray=targetKey.split(':')[0]
      targetParent[targetArray].push(subObject)
      this.setState({structureddata: newData, dataChanged: true})



    }

    columnerror(i, j, errors){
      var result=false;
      // loop over errors to see if the column has one
      errors.map((validationfailure)=>{
        if (i===validationfailure.row && j===validationfailure.column){
          result=true;
        }
      })
        return result
    }

    updateImportDays(data)
    {
      this.setState({importdays: data.value});
      const cookies=new Cookies();
      cookies.set('sgSign-importdays', data.value, { path: '/' });
    }
    
    eSignButtonLogic()
    {
      return this.props.eSign && this.state.selectedrow.sourcetype===3 ? true: false;     
    }
    
    sendeSign()
    {
      this.setState({esignlock: true})
      var body={
        accountmanager: this.state.accountmanager,
        esigndata: this.state.esigndata
      }
      invokeApig({path: '/api/sendesign/'+this.state.selectedrow.id, method: 'POST', body: body}).then(result=>{console.log(result); this.setState({editmode: false, dataChanged: false, showeSignModal: false}); this.refreshdata(this.state.importdays)}).catch(err=>{console.log(err)})
    }

    updateeSignData(field, value){
      var newData=this.state.esigndata
      newData[field]=value
      this.setState({esigndata: newData})
    }

    updateAccountManagerData(field, value){
      var newData=this.state.accountmanager
      newData[field]=value
      this.setState({accountmanager: newData})
    }

    sendesigncheck()
    {
      return !(this.state.esigndata.firstname && this.state.esigndata.lastname && this.state.esigndata.jobtitle && this.state.esigndata.email && this.state.esigndata.phonenumber && this.state.accountmanager.firstname && this.state.accountmanager.lastname && !this.state.esignlock)
    }

    processRenewal()
    {
      this.setState({processRenewalLock: true})
      invokeApig({path: '/api/processrenewal/'+this.state.selectedrow.id, method: 'POST'}).then(result=>{console.log(result); this.setState({editmode: false, dataChanged: false, processRenewalLock: false}); this.refreshdata(this.state.importdays)}).catch(err=>{console.log(err)})
    }
    
    selectPage() {
  
      const intPageNumber = parseInt(this.state.searchPage);
      this.setState({importPage: intPageNumber});
    }
        render() {
      if (this.state.isLoading) 
      return (
      <Segment className="fullpage" >  
      <LoadingPage  />
      </Segment>
      )
      const _this = this;
      const serveroptions={
        process: async function(fieldName, file, metadata, load, error, progress, abort) 
        {
          // var s3 = new AWS.S3();
          // var params = {
          //   Bucket: _this.props.bucket,
          //   Key: file.name,
          //   Body: file
          // };
          // s3.putObject(params, function(err, data) {
            // console.log(err,data)
            // if (err) 
            // {
            //   error('An error occurred uploading the file')
            // }
            // else 
            const filename=file.name.replace(/[()]/g, '').replace(/[!]/, '')
            //check file type
            const fileformat=_this.props.fileformats[_this.state.fileformatname]
            if (fileformat.source==='csv' && !filename.toUpperCase().endsWith('.CSV')  && !filename.toUpperCase().endsWith('.TXT'))
            {
              error('Invalid file type');
              return;
            }  
            console.log(fileformat.source, filename)
            

            var parameters=_this.props.parameters;
            _this.props.parameterfields.forEach(parameterField=>{parameters[parameterField]=_this.props.tpi.products[_this.state.product][parameterField]});
            uploadS3Folder(_this.props.bucket, _this.props.folder, filename, file).then(result=>
            // console.log(response);
            // if (response)
            {
              
              const data={
                importdatetime: JSONDateTime(new Date()),
                s3key: _this.props.folder+'/'+filename, 
                s3bucket: _this.props.bucket, 
                fileformat: _this.state.fileformatname,
                productdefinition: _this.state.product, 
                importstatus: 1, 
                sourcetype: _this.props.sourcetype, 
                sourceid: _this.props.sourceid, 
                userid: _this.props.userid, 
                parameters: parameters, 
                manualverification: _this.state.manualverification}
                console.log(JSON.stringify(data))
              //invokeApig({path: '/uploads', method: 'PUT', queryParams: ['bucket='+_this.props.bucket, 'key='+file.name, 'fileformat=' + _this.state.fileformatname, 'sourcetype='+_this.props.sourcetype, 'sourceid='+_this.props.sourceid] }).then(e=>{console.log(e);_this.refreshdata(_this.state.importdays);load(file.name)}).catch(e=>{console.log(e);error('An error occurred importing the file')})
              invokeApig({path: '/api/fileimports', method: 'POST', body: data}).then(e=>{console.log(e);_this.refreshdata(_this.state.importdays);load(filename)}).catch(e=>{console.log(e);error('An error occurred importing the file')})
            })
        
          
          // try {
          // const result = await uploadS3(_this.props.bucket, file.name, file)
          // if(result)
          // {
          //     const data={
          //       importdatetime: JSONDateTime(new Date()),
          //       s3key: file.name, 
          //       s3bucket: _this.props.bucket, 
          //       fileformat: JSON.stringify(_this.props.fileformats[_this.state.fileformatname]),
          //       importstatus: 1, 
          //       sourcetype: _this.props.sourcetype, 
          //       sourceid: _this.props.sourceid, 
          //       userid: _this.props.userid, 
          //       parameters: JSON.stringify(_this.props.parameters), 
          //       manualverification: _this.state.manualverification}
          //       console.log(JSON.stringify(data))
          //     //invokeApig({path: '/uploads', method: 'PUT', queryParams: ['bucket='+_this.props.bucket, 'key='+file.name, 'fileformat=' + _this.state.fileformatname, 'sourcetype='+_this.props.sourcetype, 'sourceid='+_this.props.sourceid] }).then(e=>{console.log(e);_this.refreshdata(_this.state.importdays);load(file.name)}).catch(e=>{console.log(e);error('An error occurred importing the file')})
          //     invokeApig({path: '/api/fileimports', method: 'POST', body: data}).then(e=>{
          //       console.log(e);
          //       _this.refrFeshdata(_this.state.importdays);
          //       load(file.name)})
          //       .catch(e=>{
          //         console.log(e);
          //         error('An error occurred importing the file')
          //       })
          //   }
          //   else
          //     error('An error occurred uploading the file')
          // }
          // catch {
          //   error('An error occurred uploading the file')
          // }
          }
          }
      const productOptions=Object.keys(this.props.tpi.products).filter(productkey=>{return (productkey in this.props.productdefinitions)}).map(productkey=>{
        return {key: productkey, value: productkey, text: this.props.productdefinitions[productkey].name};
      });
      if (!this.state.product && productOptions.length===1)
        this.setState({product: productOptions[0].value})
      
      var fileformatOptions=[];
      if (this.state.product && this.state.product in this.props.tpi.products)
      {
        fileformatOptions=this.props.tpi.products[this.state.product].fileformats.map(fileformat=>{return {key: fileformat, value: fileformat, text: fileformat}})
      if (!this.state.fileformatname && fileformatOptions.length>0)
        this.setState({fileformatname: fileformatOptions[0].value})
        }

      const dateoptions=[{value: 1, text: 'Show files imported in the last day', key: 1}, {value: 7, text: 'Show files imported in the last 7 days', key: 7}, {value: 30, text: 'Show files imported in the last 30 days', key: 30}, {value: 10000, text: 'Show all files', key: 10000}]
      const inlineModalStyle = {
        modal : {
          marginTop: '250px !important',
          marginLeft: 'auto',
          marginRight: 'auto'
        }
      };
      var dataview;
      var errorview;

      if (this.state.selectedrow.importstatus==3) //validation failed
      {
        if (this.state.selectedrow.fileformat!=='JSON Upload'){
        dataview=(
          <div>
          <div className="rawdata" basic>
          <Header compact className='seaglassblue' size='medium'><Icon name='table'/>Data</Header>
          <Table basic compact>
            <Table.Header>
            <Table.Row>
              {this.props.fileformats[this.state.selectedrow.fileformat].columns.map(column =><Table.HeaderCell key={column['name']}>{column['name']}</Table.HeaderCell>)}
            </Table.Row>
            </Table.Header>
            <Table.Body>
            {this.state.selectedrow.rawdata.map((row, i) =>
              <Table.Row key={i}>
                {this.props.fileformats[this.state.selectedrow.fileformat].columns.map((column, j) =>
                  <Table.Cell key={i + '_' + j}>{this.renderrawdatafield(column, i, column.column-1, this.state.selectedrow.validationfailures)}</Table.Cell>
                )}
              </Table.Row>
            )}
            </Table.Body>

          </Table>
          </div>
          <div className="errorview" basic>
          <Header  compact className='seaglassblue' size='medium'><Icon name='exclamation'/>Errors</Header>
          <Table compact selectable collapsing>
          <Table.Header>
              <Table.Row>
              <Table.HeaderCell>Row</Table.HeaderCell>
              <Table.HeaderCell>Field</Table.HeaderCell>
              <Table.HeaderCell>Error</Table.HeaderCell>
              </Table.Row>
          </Table.Header>
          <Table.Body>
          {this.state.selectedrow.validationfailures.map((validationfailure, i) =>
          {return <Table.Row key={i} active={i===this.state.selectedvalidationerror} onClick={(cell)=>{this.setState({selectedvalidationerror: i}); console.log(this.inputRefs[validationfailure.row+'_'+validationfailure.column]); if (this.state.editmode) this.inputRefs[validationfailure.row+'_'+validationfailure.column].current.focus()}}>
                  <Table.Cell>{validationfailure.row}</Table.Cell>
                  <Table.Cell>{this.getcolumn(validationfailure.column).name}</Table.Cell>
                  <Table.Cell>{validationfailure.message}</Table.Cell>
                  </Table.Row>
          })}
              
          </Table.Body>                        
        </Table>
        </div>
        </div>
        )
      } else {
        dataview=(
          <div>
          <div className="errorview" basic>
          <Header  compact className='seaglassblue' size='medium'><Icon name='exclamation'/>Errors</Header>
          <Table compact selectable collapsing>
          <Table.Header>
              <Table.Row>
              <Table.HeaderCell>Error</Table.HeaderCell>
              </Table.Row>
          </Table.Header>
          <Table.Body>
          {this.state.selectedrow.validationfailures.map((validationfailure, i) =>
          {return <Table.Row key={i} active={i===this.state.selectedvalidationerror} onClick={(cell)=>{this.setState({selectedvalidationerror: i})}}>
                  <Table.Cell>{validationfailure}</Table.Cell>
                  </Table.Row>
          })}
          </Table.Body>                        
          </Table>
          </div>
          </div>
        )
      }
      }
      else if ((this.state.selectedrow.importstatus==4 || this.state.selectedrow.importstatus==5 || this.state.selectedrow.importstatus==7 || this.state.selectedrow.importstatus==10 || this.state.selectedrow.importstatus==11 || this.state.selectedrow.importstatus==12) && this.state.selectedrow.structureddata) //manual edit or validation failed
      {
        const mergetargets=this.state.selectedrow.structureddata.map((object, i)=>'Line '+ (i+1).toString() + ': '+object.name)
        const fileformat=this.props.fileformats[this.state.selectedrow.fileformat];
        const tabPanes=this.state.selectedrow.structureddata.map((object, i)=>
        {
          const errors=this.state.selectedrow.validationfailures.filter(fail=>{return fail.line==i+1})
          var warnings=[]
          if (this.state.selectedrow.warnings)
          warnings=this.state.selectedrow.warnings.filter(fail=>{return fail.line==i+1})
          if (this.props.fileformats)
            if(this.state.selectedrow.structureddata[i].visible==true || this.state.selectedrow.structureddata[i].visible==undefined)
              return { menuItem: {key: i, color: errors.length>0 ? 'red': warnings.length>0?'yellow':'black', icon: errors.length>0 ? 'x' : warnings.length>0? 'exclamation':'check', content: 'Line '+ (i+1).toString() + ': '+object.name}   , render: ()=><Tab.Pane><JSONEditor parentid={this.state.selectedrow.id} objectid={i} rowid={this.state.selectedrowkey} object={object} editmode={this.state.editmode} entities={fileformat.targetentities} fields={fileformat.showfields} toplevelentity={fileformat.toplevelentity} manualfunctions={this.props.manualfunctions} mergeentities={fileformat.mergeentities} mergetargets={mergetargets} showsubobjects={fileformat.showsubobjects} columns={fileformat.columns} onMerge={this.mergeEntity} onDataChange={this.updateStructuredData} onFileUpload={this.updateSaveButton} onRemove={this.removeEntity} errors={errors} warnings={warnings} documentUpload={true} documentPrefix='tpi.import/fileimport' bucket={this.props.bucket}/></Tab.Pane>}
        })
        if (this.state.editmode)
          tabPanes.push({ menuItem: 'Add new', render: ()=><Tab.Pane></Tab.Pane>})
        // const tabPanes=this.state.selectedrow.structureddata.map((object, i)=>{return { menuItem:()=><Menu.Item key={i} content={'Line '+ (i+1).toString() + ': '+object.name}/>, render: ()=><Tab.Pane><JSONEditor objectid={i} object={object} editmode={this.state.editmode} entities={this.state.selectedrow.fileformat.targetentities}  toplevelentity={this.state.selectedrow.fileformat.toplevelentity} addentities={this.state.selectedrow.fileformat.addentities} mergeentities={this.state.selectedrow.fileformat.mergeentities} mergetargets={mergetargets} onMerge={this.mergeEntity} onDataChange={this.updateStructuredData} onRemove={this.removeEntity}/></Tab.Pane>}})
        
        const handleTabChange=(e, data)=>
        {
          console.log(e)
          console.log(data)
          //handle add new click
          if (data.activeIndex===this.state.selectedrow.structureddata.length)
          {
            this.addTopLevelEntitiy()
          }
          this.setState({tabIndex: data.activeIndex})
        }

        dataview=(
          <div className="structureddata">
          {/* <Menu borderless>
            <Menu.Item><Button className='seaglassbutton' disabled={!(this.state.editmode && '' in this.state.selectedrow.fileformat.manualfunctions)} onClick={()=>{this.addTopLevelEntitiy()}}><Icon name='add'/>Add</Button></Menu.Item>
          </Menu> */}
          <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={tabPanes} activeIndex={this.state.tabIndex} onTabChange={handleTabChange} />
          </div>
          
          )
      }
      const menuItemInline={paddingLeft: "3px", paddingRight: "3px"}
      
      //handle pagination
      var importPage=this.state.importPage;
      var importsfiltered=[];
      var imports=[];
      if (this.state.importFilter!==0)
        for (let importsrow in this.state.imports){
          if (this.state.importFilter==this.state.imports[importsrow].importstatus)
          {
            importsfiltered.push(this.state.imports[importsrow])
          }
        }
      else
      importsfiltered=this.state.imports;

        //   importstatus
        // }) => selected.includes(key));
        // // ((imports => imports.importstatus===this.state.assignedFilter);
      if (importsfiltered.length<(importPage-1)*PAGE_SIZE)
      {
        importPage=1;
        this.setState({importPage: 1})
      }
      imports=importsfiltered.slice((importPage-1)*PAGE_SIZE, importPage*PAGE_SIZE)
      
      const fileFormat=this.props.fileformats[this.state.fileformatname]
      var assignOptions=[{key: 0, text: '', value: 0, content: 'All'}]      
      for (let fileimportstatus in this.state.fileimportstatus){
        assignOptions.push({key: fileimportstatus, text: '-' + this.state.fileimportstatus[fileimportstatus], value: fileimportstatus, content: this.state.fileimportstatus[fileimportstatus]})
      }
     
      var uploadOption=false;
      Object.keys(this.props.tpi.products).forEach(productkey=>{if (this.props.tpi.products[productkey].fileformats.length>0) uploadOption=true})

      return (
      <div className='fullpage'> 
      {/* <FilePond labelIdle={'Drag & Drop files or <span class="filepond--label-action"> click herxe </span> to upload'} allowMultiple={true} allowRevert={false} server={serveroptions}/> */}
      <Modal style={inlineModalStyle.modal} open={this.state.showUploadModal} onClose={()=>{this.setState({showUploadModal: false})}}>
      <Modal.Header><Header className='seaglassblue'><Icon name='upload'/>Upload file</Header></Modal.Header>
   
      <Modal.Content>
      {/* <Segment basic className="importfile"> */}
        <Form inline>
        <Form.Select label='Product' value={this.state.product} options={productOptions} onChange={(event, data)=>{this.setState({product: data.value})}} />    
        <Form.Select label='File Format' value={this.state.fileformatname} options={fileformatOptions} onChange={(event, data)=>{this.setState({fileformatname: data.value})}} />    
        <Form.Checkbox label='Hold for Manual Verification' checked={this.state.manualverification} onChange={(event, data)=>{this.setState({manualverification: data.checked})}} />    
        {fileFormat && fileFormat.source==="xlsx" ? <FilePond allowMultiple={true} allowRevert={false} server={serveroptions} acceptedFileTypes={['xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']} labelFileTypeNotAllowed={'File must be an Excel file.'}/> : <FilePond allowMultiple={true} allowRevert={false} server={serveroptions}/> }
        
        </Form>
        <Button className='seaglassbutton' onClick={()=>{this.setState({showUploadModal: false})}}>Close</Button>
      {/* </Segment> */}
      
      </Modal.Content>
      </Modal> 
      
      <Modal style={inlineModalStyle.modal} open={this.state.showeSignModal}>
      <Modal.Header><Header className='seaglassblue'><Icon name='mail'/>Send e-Sign</Header></Modal.Header>
   
      <Modal.Content>
        <Form inline>
          {/* Customer e-Sign Details */}
        <Segment>
          <Header>Customer Contact Details</Header>
          <Form.Input label='First Name' required={true} placeholder='First Name' value={this.state.esigndata.firstname} onChange={(event, data)=>{this.updateeSignData('firstname', data.value)}}/>
          <Form.Input label='Last Name' required={true} placeholder='Last Name' value={this.state.esigndata.lastname} onChange={(event, data)=>{this.updateeSignData('lastname', data.value)}}/>
          <Form.Input label='Job Title' required={true} placeholder='Job Title' value={this.state.esigndata.jobtitle} onChange={(event, data)=>{this.updateeSignData('jobtitle', data.value)}}/>
          <Form.Input label='eMail' required={true} placeholder='eMail' value={this.state.esigndata.email} onChange={(event, data)=>{this.updateeSignData('email', data.value)}}/>
          <Form.Input label='Phone' required={true} placeholder='Phone' value={this.state.esigndata.phonenumber} onChange={(event, data)=>{this.updateeSignData('phonenumber', data.value)}}/>
        </Segment>
        <Segment>
        <Header>Account Manager Details</Header>
          <Form.Input label='First Name' required={true} placeholder='First Name' value={this.state.accountmanager.firstname} onChange={(event, data)=>{this.updateAccountManagerData('firstname', data.value)}}/>
          <Form.Input label='Last Name' required={true} placeholder='Last Name' value={this.state.accountmanager.lastname} onChange={(event, data)=>{this.updateAccountManagerData('lastname', data.value)}}/>
        </Segment>
        </Form>
        <Button className='seaglassbutton' disabled={this.sendesigncheck()} onClick={()=>{this.sendeSign()}}>Send</Button>
        <Button className='seaglassbutton' onClick={()=>{this.setState({showeSignModal: false, esignlock: false})}}>Close</Button>
      
      </Modal.Content>
      </Modal> 
      <Menu style={{height: "45px"}} className='menubar' secondary>
        {console.log('this.state.selectedrow', this.state.selectedrow)}
        {/* <Menu.Item><Header as='h3' className='seaglassblue'>Recent Uploads</Header></Menu.Item> */}
        {uploadOption && <Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.setState({showUploadModal: true})}} disabled={this.state.editmode}><Icon name='upload'/>Upload</Button></Menu.Item>}
        {this.props.manualuploadformat &&<Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.manualUpload()}} disabled={this.state.editmode}><Icon name='keyboard'/>Manual Entry</Button></Menu.Item>}
        <Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.setState({editmode: true})}} disabled={this.state.selectedrowkey===-1 || this.state.editmode || this.state.selectedrow.importstatus==7 || this.state.selectedrow.importstatus==10 || this.state.selectedrow.importstatus==11 || this.state.selectedrow.importstatus==12}><Icon name='edit'/>Edit</Button></Menu.Item>
        {this.eSignButtonLogic() ? this.state.selectedrow.importstatus==12 ? <Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.processRenewal()}} disabled={this.state.editmode || this.state.processRenewalLock}><Icon name='mail'/>Process Renewal</Button></Menu.Item> :
        <Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.setState({showeSignModal: true})}} disabled={this.state.editmode || this.state.selectedrow.importstatus!=4}><Icon name='mail'/>Send e-Sign</Button></Menu.Item> :
        <Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.saveUpload()}} disabled={this.state.editmode || this.state.selectedrow.importstatus!==4}><Icon name='check'/>Approve</Button></Menu.Item>}
        <Menu.Item style={menuItemInline}><Dropdown className='seaglassbutton icon'  text='Reject' icon='x' button labeled disabled={this.state.editmode || this.state.selectedrow.importstatus==7 || this.state.selectedrow.importstatus==8 || this.state.selectedrow.importstatus==9 || this.state.selectedrow.importstatus==10 || this.state.selectedrow.importstatus===null}>
        <Dropdown.Menu>
        {Object.keys(this.state.fileimportrejectionreason).map(rejectionreasonid=>{return <Dropdown.Item onClick={()=>{this.rejectImport(rejectionreasonid)}}>{this.state.fileimportrejectionreason[rejectionreasonid]}</Dropdown.Item>})}
        </Dropdown.Menu>
        </Dropdown>
        </Menu.Item>
        {/* <Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.rejectImport()}} ><Icon name='x'/>Reject</Button></Menu.Item> */}
        <Menu.Item primary position='right'><Select label='Import Date' value={this.state.importdays} options={dateoptions} onChange={(event, data)=>{this.updateImportDays(data); this.refreshdata(data.value)}} />    </Menu.Item>
        <Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.refreshbutton()}} disabled={this.state.editmode}><Icon name='refresh'/>Refresh</Button></Menu.Item>
        {/* <Menu.Item position='right'><Button onClick={()=>{this.setState({isLoading: true});this.refreshdata(this.state.importdays);}}><Icon name='refresh'/>Refresh</Button></Menu.Item> */}

      </Menu>
      <div basic className="uploads">
      <Table striped compact>
        <Table.Header>
            <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Import Date Time</Table.HeaderCell>
            <Table.HeaderCell>Uploaded By</Table.HeaderCell>
            <Table.HeaderCell>File Name</Table.HeaderCell>
            <Table.HeaderCell>Product Definition</Table.HeaderCell>
            <Table.HeaderCell>Notes</Table.HeaderCell>
            <Table.HeaderCell>Import Status 
            <Dropdown inline onChange={(event, data)=>{this.setState({importFilter: data.value})}} options={assignOptions} defaultValue={assignOptions[0].value}/>
            </Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
        {imports.map((row, i) =>
        
        {
          var fileName="Manually Entered";
        if (row.s3key)
        {
          const parseKey=row.s3key.split('/');
          fileName=parseKey[parseKey.length-1];
        }
          return <Table.Row key={i} active={i===this.state.selectedrowkey} onClick={(cell)=>{if (!this.state.editmode) this.getrowdata(row.id, i)}}>
                <Table.Cell>{row.sourcefileid}</Table.Cell>
                <Table.Cell>{formatDateTime(new Date(row.importdatetime))}</Table.Cell>
                <Table.Cell>{row.username}</Table.Cell>
                <Table.Cell>{fileName}</Table.Cell>
                <Table.Cell>{row.productdefinition}</Table.Cell>
                <Table.Cell>{row.description ? row.description : row.sourcetype===3 ? 'Renewal': row.sourcetype===2 ? 'Site Addition' : row.customercount +' Customer'+(row.customercount>1?'s':'')}</Table.Cell>
                <Table.Cell>{this.state.fileimportstatus[row.importstatus] + (row.importstatus===10 && row.rejectionreason ? (' ('+this.state.fileimportrejectionreason[row.rejectionreason]+')') : '')}</Table.Cell>

                </Table.Row>
        })}
            
        </Table.Body>                        
        </Table>
        <Pagination disabled={this.state.editmode} activePage={this.state.importPage} totalPages={Math.ceil(importsfiltered.length/PAGE_SIZE)} onPageChange={(event, { activePage })=>{if (!this.state.editmode) this.setState({importPage: activePage})}}/> 
        <Input disabled={this.state.editmode} className="pageinput" size='mini' placeholder='Page...' type='number' min='1' max={Math.ceil(importsfiltered.length/PAGE_SIZE)} onChange={(event, data)=>{this.setState({searchPage: data.value})}}/>
        <Button disabled={this.state.editmode} icon className='seaglassbutton' onClick={(cell)=>{this.selectPage()}}><Icon name='arrow right'/></Button>
        
      </div>
        {dataview}
        <Menu secondary>
        <Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.saveUpload()}} disabled={!(this.state.editmode && this.state.dataChanged)}><Icon name='save'/>Save</Button></Menu.Item>
        <Menu.Item style={menuItemInline}><Button className='seaglassbutton' onClick={()=>{this.setState({editmode: false}); this.refreshdata(this.state.importdays)}} disabled={!this.state.editmode}><Icon name='cancel'/>Cancel</Button></Menu.Item>
        </Menu>
      
      </div>

      );
    }
  }